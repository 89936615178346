<template>
  <v-navigation-drawer v-if="$auth.isAuthenticated && permissions.scope === 'read:homeowner'"
    id="core-navigation-drawer"
    v-model="drawer"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    width="280"
    v-bind="$attrs"
    dark
  >

  <!-- :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'" -->

    <v-divider class="mb-2" />

    <v-list
      dense
      nav
    >
      <v-list-item>
        <v-list-item-icon
          class="align-self-center"
          color="white"
          contain
        >
          <v-img
            src= "https://static.wixstatic.com/media/1af86c_9e8d3b5cf7c84cdc9094307c5959ebda.png/v1/fill/w_312,h_127,al_c,q_85,usm_0.66_1.00_0.01/1af86c_9e8d3b5cf7c84cdc9094307c5959ebda.webp"
            max-height="60"
            max-width="50"
          />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            class="display-1"
            v-text="company.title"
          />
        </v-list-item-content>

      </v-list-item>
    </v-list>

    
    <v-divider class="mb-2" />

    <v-list
      expand
      nav
      
    >
    
    </v-list>

    <v-divider class="mb-2" />


    <v-autocomplete
    ref="user"
    :items="user"
    v-model="selected_user.Address"
    item-text="Address"
    label="MUST SELECT ADDRESS"
    prepend-icon="mdi-account-search"
    outlined
    solo
    menu-props="auto"
    class="px-3 success"
    required
    return-object
    @change="get_home_id(selected_user.HomeId)"
    @click="run_dropdown()"
    ></v-autocomplete>

    <v-list
      expand
      nav
      
    >
      <div />

        <template v-for="(item, i) in computed_drawer_homeowner_items">
          
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
          </base-item-group>

          <base-item
            v-else
            :key="`item-${i}`"
            :item="item"
          />

        </template>

      <div />
    
    </v-list>

    <v-divider class="mb-2" />

    <v-list
      expand
      nav
    >
    
    </v-list>

  </v-navigation-drawer>
</template>

<script>
  // Utilities
  // import {
  //   mapState
  // } from 'vuex'

  import axios from "axios";

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({

      drawer_homeowner_items: [
        {
          icon: 'mdi-view-dashboard',
          title: 'homeownerdashboard',
          to: '/homeowner/dashboard',
        },
        {
          icon: 'mdi-home-search-outline',
          title: 'Home Updates',
          to: '/homeowner/home-updates',
        },
        // {
        //   icon: 'mdi-file-document-edit-outline',
        //   title: 'homeownerpurchaseagreement',
        //   to: '/homeowner/purchase-agreement',
        // },
        {
          icon: 'mdi-home-search-outline',
          title: 'homeownerhomeselections',
          group: '/home_selections',
          children:[
            {
              title: 'Section 1',
              to: 'section-1',
            },
            {
              title: 'Section 2',
              to: 'section-2',
            },
            {
              title: 'Section 3',
              to: 'section-3',
            },
            {
              title: 'Section 4',
              to: 'section-4',
            },
            {
              title: 'Section A',
              to: 'section-a',
            },
            {
              title: 'Section B',
              to: 'section-b',
            },
            {
              title: 'Section C',
              to: 'section-c',
            },
            {
              title: 'Section D',
              to: 'section-d',
            },
            {
              title: 'Section F',
              to: 'section-f',
            },
            {
              title: 'Section 5',
              to: 'section-5',
            },
            {
              title: 'Section 6',
              to: 'section-6',
            },
            {
              title: 'Section 7',
              to: 'section-7',
            },
            {
              title: 'Section 8',
              to: 'section-8',
            },
            {
              title: 'Section I',
              to: 'section-i',
            },
            {
              title: 'Section J',
              to: 'section-j',
            },

          ]
        },
        {
          icon: 'mdi-home-city-outline',
          title: 'homeownervendorselection',
          group:'/vendor_selections',
          children:[
            {
                title: 'Vendor Appointments',
                to: 'vendor-appointments',
              },
              {
                title: 'allowancesummary',
                to: 'allowance-summary',
              },
          ],
        },
        {
          icon: 'mdi-clipboard-flow-outline',
          title: 'homeownerchangeorder',
          to: '/homeowner/change-order',
        },
        {
          icon: 'mdi-home-edit-outline',
          title: 'homeownerhomedesign',
          to: '/homeowner/home-design',
        },
        {
          icon: 'mdi-upload',
          title: 'homeowneruploads',
          to: '/homeowner/uploads',
        },
        {
          icon: 'mdi-currency-usd',
          title: 'homeownercostbreakdown',
          group: '/homeowner',
          children :[
            {
              title: 'homeownercostbreakdownpageone',
              to: 'cost-breakdown-page-one',
            },
            {
              title: 'homeownercostbreakdownpagetwo',
              to: 'cost-breakdown-page-two',
            }
          ]
        },
      ],
      user: [],
        selected_user: {
            UserId:0,
            HomeId:'',
            PrimaryName: '',
            SecondaryName: '',
            Role:'',
            Address:''
        },
        default_user: {
            UserId:0,
            HomeId:'',
            PrimaryName: '',
            SecondaryName: '',
            Role:'',
            Address:''
        },

    }),
    computed: {
      // ...mapState(['barColor']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },

      // computed_drawer_staff_items () {
      //   return this.drawer_staff_items.map(this.mapItem)
      // },

      computed_drawer_homeowner_items () {
        return this.drawer_homeowner_items.map(this.mapItem)
      },

      // computed_drawer_agent_items () {
      //   return this.drawer_agent_items.map(this.mapItem)
      // },

      company () {
        return {
          // avatar: true,
          title: this.$t('staffcompanyname'),
        }
      },
      permissions(){
        return {
          scope: localStorage.getItem("permissions"),
        }
      },
      homeownername(){
        return {
          homeowner: localStorage.getItem("homeowner"),
        }
      },
      formTitle () {
        return this.editedIndex === -1 ? 'New Person' : 'Edit User'
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    created () {
      this.initialize()
    },
  methods:{
      initialize () {
            axios
            .get(process.env.VUE_APP_GET_HOMEOWNER_HOME_ID_URL,
            {
            params: {
                 email_address: this.$auth.user.email || localStorage.getItem('homeownerEmail') 
            }
            })
        .then(response => {
          this.user = response.data
          console.log("😀 this.user is HomeOwnerNav",this.user)
        })
        .catch(error => {
          console.log(error)
        })
        },
        view_user (user) {
          this.editedIndex = this.user.indexOf(user)
          this.selected_user = Object.assign({}, user)
          console.log(this.user)
          this.dialog = true
        },
        close () {
          this.dialog = false
          this.$nextTick(() => {
            this.selected_user= Object.assign({}, this.default_user)
            this.editedIndex = -1
          })
        },
    get_home_id () {
          var body ={
              "selected_user": this.selected_user.Address,
            }
          localStorage.setItem('homeid', JSON.parse(body.selected_user.HomeId))
        },
      run_dropdown (){
        this.initialize()
      },
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
      changeRoute(a) {
        this.$router.push({path: a.path })
        console.log(a)
      },
    },
  }
</script>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
